export default {
  // https://www.shadertoy.com/view/llfGR7
  posterize: {
    frag: `
    const float pixel_w = 10.;
    const float pixel_h = 10.;
    const float color_r = 0.25;

    void mainImage( out vec4 fragColor, in vec2 fragCoord )
    {
      float vx_offset = 0.5;

      vec2 uv = vec2(fragCoord.x / iResolution.x, 1.0 - (fragCoord.y / iResolution.y));
      vec4 c = texture2D(iChannel0, uv);
      vec3 tc = vec3(1., 0., 0.);

      vec2 coord = vec2(0.,0.);

      if(uv.x < (vx_offset-0.001))
      {
          float dx = 1./160.;
          float dy = 1./144.;
          coord = vec2(dx*floor(uv.x/dx),
                       dy*floor(uv.y/dy));
      }
      else if (uv.x>=(vx_offset+0.001))
      {
          coord = uv;
      }

      tc = texture2D(iChannel0, coord).rgb;

      // Ramp colors
      float brightness = sqrt(
          0.299* (tc.r*tc.r) +
          0.587* (tc.g*tc.g) +
          0.114* (tc.b*tc.b) );
      float target_c = color_r*floor(brightness/color_r);
      tc = vec3(target_c, target_c, target_c);

      fragColor = vec4(tc, 1.0);
    }
    `,
  },

  // https://www.shadertoy.com/view/tsX3RN
  scanlines1: {
    frag: `
    float maxStrength = 0.5;
    float minStrength = 0.125;

    float speed = 10.00;

    float random (vec2 noise)
    {
        //--- Noise: Low Static (X axis) ---
        //return fract(sin(dot(noise.yx,vec2(0.000128,0.233)))*804818480.159265359);

        //--- Noise: Low Static (Y axis) ---
        //return fract(sin(dot(noise.xy,vec2(0.000128,0.233)))*804818480.159265359);

        //--- Noise: Low Static Scanlines (X axis) ---
        //return fract(sin(dot(noise.xy,vec2(98.233,0.0001)))*925895933.14159265359);

        //--- Noise: Low Static Scanlines (Y axis) ---
        //return fract(sin(dot(noise.xy,vec2(0.0001,98.233)))*925895933.14159265359);

        //--- Noise: High Static Scanlines (X axis) ---
        //return fract(sin(dot(noise.xy,vec2(0.0001,98.233)))*12073103.285);

        //--- Noise: High Static Scanlines (Y axis) ---
        //return fract(sin(dot(noise.xy,vec2(98.233,0.0001)))*12073103.285);

        //--- Noise: Full Static ---
        return fract(sin(dot(noise.xy,vec2(10.998,98.233)))*12433.14159265359);
    }

    float random_colour (float noise)
    {
        return fract(sin(noise));
    }

    void mainImage(out vec4 fragColour, in vec2 fragCoord)
    {
        vec2 uv = fragCoord.xy / iResolution.xy;
        vec2 uv2 = fract(fragCoord.xy / iResolution.xy * fract(sin(iTime * speed)));

        //--- Strength animate ---
        maxStrength = clamp(sin(iTime/2.0),minStrength,maxStrength);
        //-----------------------

        //--- Black and white ---
        vec3 colour = vec3(random(uv2.xy))*maxStrength;
        //-----------------------

        /*
        //--- Colour ---
        colour.r *= random_colour(sin(iTime*speed));
        colour.g *= random_colour(cos(iTime*speed));
        colour.b *= random_colour(tan(iTime*speed));
        //--------------
        */

        //--- Background ---
        vec3 background = vec3(texture2D(iChannel0, vec2(uv.x, 1.0 - uv.y)));
        //--------------

        fragColour = vec4(background-colour, 1.0);
    }
    `,
  },

  scanlines2: {
    frag: `
    // change these values to 0.0 to turn off individual effects
    float vertJerkOpt = 1.0;
    float vertMovementOpt = 1.0;
    float bottomStaticOpt = 1.0;
    float scalinesOpt = 2.0;
    float rgbOffsetOpt = 1.0;
    float horzFuzzOpt = 4.0;

    // Noise generation functions borrowed from:
    // https://github.com/ashima/webgl-noise/blob/master/src/noise2D.glsl

    vec3 mod289(vec3 x) {
      return x - floor(x * (1.0 / 289.0)) * 289.0;
    }

    vec2 mod289(vec2 x) {
      return x - floor(x * (1.0 / 289.0)) * 289.0;
    }

    vec3 permute(vec3 x) {
      return mod289(((x*34.0)+1.0)*x);
    }

    float snoise(vec2 v)
      {
      const vec4 C = vec4(0.211324865405187,  // (3.0-sqrt(3.0))/6.0
                          0.366025403784439,  // 0.5*(sqrt(3.0)-1.0)
                         -0.577350269189626,  // -1.0 + 2.0 * C.x
                          0.024390243902439); // 1.0 / 41.0
    // First corner
      vec2 i  = floor(v + dot(v, C.yy) );
      vec2 x0 = v -   i + dot(i, C.xx);

    // Other corners
      vec2 i1;
      //i1.x = step( x0.y, x0.x ); // x0.x > x0.y ? 1.0 : 0.0
      //i1.y = 1.0 - i1.x;
      i1 = (x0.x > x0.y) ? vec2(1.0, 0.0) : vec2(0.0, 1.0);
      // x0 = x0 - 0.0 + 0.0 * C.xx ;
      // x1 = x0 - i1 + 1.0 * C.xx ;
      // x2 = x0 - 1.0 + 2.0 * C.xx ;
      vec4 x12 = x0.xyxy + C.xxzz;
      x12.xy -= i1;

    // Permutations
      i = mod289(i); // Avoid truncation effects in permutation
      vec3 p = permute( permute( i.y + vec3(0.0, i1.y, 1.0 ))
        + i.x + vec3(0.0, i1.x, 1.0 ));

      vec3 m = max(0.5 - vec3(dot(x0,x0), dot(x12.xy,x12.xy), dot(x12.zw,x12.zw)), 0.0);
      m = m*m ;
      m = m*m ;

    // Gradients: 41 points uniformly over a line, mapped onto a diamond.
    // The ring size 17*17 = 289 is close to a multiple of 41 (41*7 = 287)

      vec3 x = 2.0 * fract(p * C.www) - 1.0;
      vec3 h = abs(x) - 0.5;
      vec3 ox = floor(x + 0.5);
      vec3 a0 = x - ox;

    // Normalise gradients implicitly by scaling m
    // Approximation of: m *= inversesqrt( a0*a0 + h*h );
      m *= 1.79284291400159 - 0.85373472095314 * ( a0*a0 + h*h );

    // Compute final noise value at P
      vec3 g;
      g.x  = a0.x  * x0.x  + h.x  * x0.y;
      g.yz = a0.yz * x12.xz + h.yz * x12.yw;
      return 130.0 * dot(m, g);
    }

    float fnoise(vec2 v) {
      return fract(sin(dot(v, vec2(12.9898, 78.233))) * 43758.5453) * 0.55;
    }

    float staticV(vec2 uv) {
        float staticHeight = fnoise(vec2(9.0,iTime*1.2+3.0))*0.3+5.0;
        float staticAmount = fnoise(vec2(1.0,iTime*1.2-6.0))*0.1+0.3;
        float staticStrength = fnoise(vec2(-9.75,iTime*0.6-3.0))*2.0+2.0;
      return (1.0-step(fnoise(vec2(5.0*pow(iTime,2.0)+pow(uv.x*7.0,1.2),pow((mod(iTime,100.0)+100.0)*uv.y*0.3+3.0,staticHeight))),staticAmount))*staticStrength;
    }

    void mainImage( out vec4 fragColor, in vec2 fragCoord )
    {

      vec2 uv =  fragCoord.xy / iResolution.xy;

      float fuzzOffset = fnoise(vec2(iTime*15.0,uv.y*80.0))*0.003;
      float largeFuzzOffset = fnoise(vec2(iTime*1.0,uv.y*25.0))*0.004;

        float vertMovementOn = (1.0-step(snoise(vec2(iTime*0.2,8.0)),0.4))*vertMovementOpt;
        float vertJerk = (1.0-step(fnoise(vec2(iTime*1.5,5.0)),0.6))*vertJerkOpt;
        float vertJerk2 = (1.0-step(fnoise(vec2(iTime*5.5,5.0)),0.2))*vertJerkOpt;
        float yOffset = abs(sin(iTime)*4.0)*vertMovementOn+vertJerk*vertJerk2*0.3;
        float y = mod(uv.y+yOffset,1.0);

      float xOffset = (fuzzOffset + largeFuzzOffset) * horzFuzzOpt;

        float staticVal = 0.0;

        for (float y = -1.0; y <= 1.0; y += 1.0) {
            float maxDist = 5.0/200.0;
            float dist = y/200.0;
          staticVal += staticV(vec2(uv.x,uv.y+dist))*(maxDist-abs(dist))*1.5;
        }

        staticVal *= bottomStaticOpt;

      float red  =   texture2D( iChannel0,  vec2(uv.x + xOffset -0.01*rgbOffsetOpt, 1.0 - y)).r+staticVal;
      float green =  texture2D( iChannel0,  vec2(uv.x + xOffset, 1.0 - y)).g+staticVal;
      float blue  = texture2D( iChannel0,  vec2(uv.x + xOffset +0.01*rgbOffsetOpt, 1.0 - y)).b+staticVal;

      vec3 color = vec3(red,green,blue);
      float scanline = sin(uv.y*800.0)*0.04*scalinesOpt;
      color -= scanline;

      fragColor = vec4(color,1.0);
    }
    `,
  },

  scanlines3: {
    frag: `
    // change these values to 0.0 to turn off individual effects
    float vertJerkOpt = 1.0;
    float vertMovementOpt = 1.0;
    float bottomStaticOpt = 1.0;
    float scalinesOpt = 1.0;
    float rgbOffsetOpt = 1.0;
    float horzFuzzOpt = 1.0;

    // Noise generation functions borrowed from:
    // https://github.com/ashima/webgl-noise/blob/master/src/noise2D.glsl

    vec3 mod289(vec3 x) {
      return x - floor(x * (1.0 / 289.0)) * 289.0;
    }

    vec2 mod289(vec2 x) {
      return x - floor(x * (1.0 / 289.0)) * 289.0;
    }

    vec3 permute(vec3 x) {
      return mod289(((x*34.0)+1.0)*x);
    }

    float snoise(vec2 v)
      {
      const vec4 C = vec4(0.211324865405187,  // (3.0-sqrt(3.0))/6.0
                          0.366025403784439,  // 0.5*(sqrt(3.0)-1.0)
                         -0.577350269189626,  // -1.0 + 2.0 * C.x
                          0.024390243902439); // 1.0 / 41.0
    // First corner
      vec2 i  = floor(v + dot(v, C.yy) );
      vec2 x0 = v -   i + dot(i, C.xx);

    // Other corners
      vec2 i1;
      //i1.x = step( x0.y, x0.x ); // x0.x > x0.y ? 1.0 : 0.0
      //i1.y = 1.0 - i1.x;
      i1 = (x0.x > x0.y) ? vec2(1.0, 0.0) : vec2(0.0, 1.0);
      // x0 = x0 - 0.0 + 0.0 * C.xx ;
      // x1 = x0 - i1 + 1.0 * C.xx ;
      // x2 = x0 - 1.0 + 2.0 * C.xx ;
      vec4 x12 = x0.xyxy + C.xxzz;
      x12.xy -= i1;

    // Permutations
      i = mod289(i); // Avoid truncation effects in permutation
      vec3 p = permute( permute( i.y + vec3(0.0, i1.y, 1.0 ))
        + i.x + vec3(0.0, i1.x, 1.0 ));

      vec3 m = max(0.5 - vec3(dot(x0,x0), dot(x12.xy,x12.xy), dot(x12.zw,x12.zw)), 0.0);
      m = m*m ;
      m = m*m ;

    // Gradients: 41 points uniformly over a line, mapped onto a diamond.
    // The ring size 17*17 = 289 is close to a multiple of 41 (41*7 = 287)

      vec3 x = 2.0 * fract(p * C.www) - 1.0;
      vec3 h = abs(x) - 0.5;
      vec3 ox = floor(x + 0.5);
      vec3 a0 = x - ox;

    // Normalise gradients implicitly by scaling m
    // Approximation of: m *= inversesqrt( a0*a0 + h*h );
      m *= 1.79284291400159 - 0.85373472095314 * ( a0*a0 + h*h );

    // Compute final noise value at P
      vec3 g;
      g.x  = a0.x  * x0.x  + h.x  * x0.y;
      g.yz = a0.yz * x12.xz + h.yz * x12.yw;
      return 130.0 * dot(m, g);
    }

    float staticV(vec2 uv) {
        float staticHeight = snoise(vec2(9.0,iTime*1.2+3.0))*0.3+5.0;
        float staticAmount = snoise(vec2(1.0,iTime*1.2-6.0))*0.1+0.3;
        float staticStrength = snoise(vec2(-9.75,iTime*0.6-3.0))*2.0+2.0;
      return (1.0-step(snoise(vec2(5.0*pow(iTime,2.0)+pow(uv.x*7.0,1.2),pow((mod(iTime,100.0)+100.0)*uv.y*0.3+3.0,staticHeight))),staticAmount))*staticStrength;
    }


    void mainImage( out vec4 fragColor, in vec2 fragCoord )
    {

      vec2 uv =  fragCoord.xy/iResolution.xy;

      float jerkOffset = (1.0-step(snoise(vec2(iTime*1.3,5.0)),0.8))*0.05;

      float fuzzOffset = snoise(vec2(iTime*15.0,uv.y*80.0))*0.003;
      float largeFuzzOffset = snoise(vec2(iTime*1.0,uv.y*25.0))*0.004;

        float vertMovementOn = (1.0-step(snoise(vec2(iTime*0.2,8.0)),0.4))*vertMovementOpt;
        float vertJerk = (1.0-step(snoise(vec2(iTime*1.5,5.0)),0.6))*vertJerkOpt;
        float vertJerk2 = (1.0-step(snoise(vec2(iTime*5.5,5.0)),0.2))*vertJerkOpt;
        float yOffset = abs(sin(iTime)*4.0)*vertMovementOn+vertJerk*vertJerk2*0.3;
        float y = mod(uv.y+yOffset,1.0);


      float xOffset = (fuzzOffset + largeFuzzOffset) * horzFuzzOpt;

        float staticVal = 0.0;

        for (float y = -1.0; y <= 1.0; y += 1.0) {
            float maxDist = 5.0/200.0;
            float dist = y/200.0;
          staticVal += staticV(vec2(uv.x,uv.y+dist))*(maxDist-abs(dist))*1.5;
        }

        staticVal *= bottomStaticOpt;

      float red  =   texture2D( iChannel0,  vec2(uv.x + xOffset -0.01*rgbOffsetOpt, 1.0 - y)).r+staticVal;
      float green =  texture2D( iChannel0,  vec2(uv.x + xOffset, 1.0 - y)).g+staticVal;
      float blue  = texture2D( iChannel0,  vec2(uv.x + xOffset +0.01*rgbOffsetOpt, 1.0 - y)).b+staticVal;

      vec3 color = vec3(red,green,blue);
      float scanline = sin(uv.y*800.0)*0.04*scalinesOpt;
      color -= scanline;

      fragColor = vec4(color,1.0);
    }
    `,
  },

  scanlines4: {
    frag: `
    //2D (returns 0 - 1)
    float random2d(vec2 n) {
        return fract(sin(dot(n, vec2(12.9898, 4.1414))) * 43758.5453);
    }

    float randomRange (in vec2 seed, in float min, in float max) {
        return min + random2d(seed) * (max - min);
    }

    // return 1 if v inside 1d range
    float insideRange(float v, float bottom, float top) {
       return step(bottom, v) - step(top, v);
    }

    //inputs
    float AMT = 0.2; //0 - 1 glitch amount
    float SPEED = 0.6; //0 - 1 speed
    const float maxIterations = 10.0 * 0.2; // AMT;

    void mainImage( out vec4 fragColor, in vec2 fragCoord )
    {
      float time = floor(iTime * SPEED * 60.0);
      vec2 uv = vec2(fragCoord.x / iResolution.x, 1.0 - (fragCoord.y / iResolution.y));

        //copy orig
        vec3 outCol = texture2D(iChannel0, uv).rgb;

        //randomly offset slices horizontally
        float maxOffset = AMT/2.0;
        for (float i = 0.0; i < maxIterations; i += 1.0) {
            float sliceY = random2d(vec2(time , 2345.0 + float(i)));
            float sliceH = random2d(vec2(time , 9035.0 + float(i))) * 0.25;
            float hOffset = randomRange(vec2(time , 9625.0 + float(i)), -maxOffset, maxOffset);
            vec2 uvOff = uv;
            uvOff.x += hOffset;
            if (insideRange(uv.y, sliceY, fract(sliceY+sliceH)) == 1.0 ){
              outCol = texture2D(iChannel0, uvOff).rgb;
            }
        }

        //do slight offset on one entire channel
        float maxColOffset = AMT/6.0;
        float rnd = random2d(vec2(time , 9545.0));
        vec2 colOffset = vec2(randomRange(vec2(time , 9545.0),-maxColOffset,maxColOffset),
                           randomRange(vec2(time , 7205.0),-maxColOffset,maxColOffset));
        if (rnd < 0.33){
            outCol.r = texture2D(iChannel0, uv + colOffset).r;

        }else if (rnd < 0.66){
            outCol.g = texture2D(iChannel0, uv + colOffset).g;

        } else{
            outCol.b = texture2D(iChannel0, uv + colOffset).b;
        }

      fragColor = vec4(outCol, 1.0);
    }
    `,
  },

  colorGlow: {
    frag: `
    void mainImage( out vec4 o, in vec2 q )
    {
        o = texture2D(iChannel0, vec2(q.x / iResolution.x, 1.0 - q.y / iResolution.y));
        vec4 f = vec4(1.,1.,1.,1.);//rgb frequencies

        //sine glow
        o = .5*(1.0+sin(6.28318*o+iTime*f));

        //ping pong glow
        o = abs(mod(o+iTime*f,2.)-1.);
    }
    `,
  },

  oldFilm: {
    // https://www.shadertoy.com/view/7lKcDD
    frag: `
    #define int2 vec2
    #define float2 vec2
    #define int3 vec3
    #define float3 vec3
    #define int4 vec4
    #define float4 vec4
    #define frac fract
    #define float2x2 mat2
    #define float3x3 mat3
    #define float4x4 mat4
    #define saturate(x) clamp(x,0.,1.)
    #define lerp mix
    #define CurrentTime (iTime)
    #define sincos(x,s,c) s = sin(x),c = cos(x)
    #define mul(x,y) (x*y)
    #define atan2 atan
    #define fmod mod
    #define static

    float2 hash(float2 p)
    {
        float3 p3 = frac(float3(p.xyx) * float3(166.1031, 147.1030, 142.0973));
        p3 += dot(p3, p3.yzx + 33.33);
        return frac((p3.xx + p3.yz) * p3.zy);
    }

    float simplexNoise(float2 uv)
    {
        const float k1 = 0.366025;
        const float k2 = 0.211324;

        int2 idx = floor(uv + (uv.x + uv.y) * k1);
        float2 a = uv - (float2(idx) - float(idx.x + idx.y) * k2);
        int2 tb = a.y > a.x ? int2(0, 1) : int2(1, 0);
        float2 b = a - tb + k2;
        float2 c = a - 1. + k2 * 2.;

        float3 kernel = max(0.5 - float3(dot(a, a), dot(b, b), dot(c, c)), 0.);
        float3 noise = kernel * kernel * kernel * kernel *
        float3(dot(a, hash(idx)*2.-1.),
               dot(b, hash(idx + tb)*2.-1.),
               dot(c, hash(idx + 1.)*2.-1.));

        return dot(float3(70.), noise);
    }


    float verticalLine(float2 uv, float time)
    {
        float uvX = uv.x + time*0.0000003;
        float2 xHash = hash(float2(uvX,uvX));
        float vertical = step(0.9999993,sin(uvX*1000.0 * (xHash.x*0.01+0.01)));

        float uvY = uv.y + time*0.000001;
        float2 yHash = hash(float2(uvY,uvY));
        vertical *= sin(uvY*1000.0 * (yHash.x));


        return saturate(1.0 - vertical);
    }

    void mainImage( out vec4 fragColor, in vec2 fragCoord )
    {
        vec2 uv = vec2(fragCoord.x / iResolution.x, 1.0 - (fragCoord.y / iResolution.y));
        float t = iTime;

        float3 col = float3(.5, .5, .5);
        col += verticalLine(uv,t)*0.5;
        col += (1.0 - verticalLine(uv-3.0,t*5.0))*0.5;
        col *= smoothstep(0.9,0.83, simplexNoise((uv + hash(float2(t,t))*154.4541-154.4541)*10.0));
        col *= saturate(1. - hash(uv + t * 0.01).x * 0.25);
        col *= smoothstep(1.4,0.0, length(uv-0.5));

        fragColor = vec4(col*texture2D(iChannel0,uv).rgb,1.0);
    }
    `,
  },

  dotted: {
    frag: `
    float circle(in vec2 _st, in float _radius){
        vec2 dist = _st-vec2(0.5);
      return 1.-smoothstep(_radius-(_radius*0.01),
                             _radius+(_radius*0.01),
                             dot(dist,dist)*4.0);
    }

    const float maxVal = 1.0;
    const float minVal = 0.5;

    void mainImage( out vec4 fragColor, in vec2 fragCoord )
    {
        vec2 textureUv = vec2(fragCoord.x / iResolution.x, 1.0 - (fragCoord.y / iResolution.y));
        vec2 uv = fragCoord/iResolution.y;

        vec4 col = texture2D(iChannel0, textureUv);

        vec2 squareUV = fract(uv * 100.0);
        float tVal = sin(iTime+uv.x*2.0);
        float lng = circle(squareUV, maxVal- tVal * minVal);

        if(lng <= 0.1)
            col = vec4(0.0);

        fragColor = col;
    }
    `,
  },
};
