
import { mapGetters } from 'vuex';
import { Haptics, ImpactStyle } from '@capacitor/haptics';

import uiMixin from '../mixins/ui';

export default {
  name: 'CompanionCard',
  mixins: [
    uiMixin,
  ],
  props: {
    name: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    ...mapGetters('companions', [
      'companionActive',
    ]),
    companion() {
      return this.$store.getters['companions/getCompanionByName'](this.name);
    },
    isValidCompanion() {
      return !!this.companion;
    },
    isCompanionActive() {
      return this.companion?.name === this.companionActive?.name;
    },
    countResource() {
      return this.isValidCompanion
        ? this.$store.getters[`resources/count${this.companion.loyaltyResourceName}`]
        : undefined;
    },
    resource() {
      return this.isValidCompanion
        ? this.$store.state.resources.resources[this.companion.loyaltyResourceName]
        : undefined;
    },
    loyaltyCost() {
      let c = 0;

      if (this.isValidCompanion && this.companion.loyaltyPerResource) {
        c = 1 / this.companion.loyaltyPerResource;
      }

      return c;
    },
  },
  methods: {
    async handleLoyaltyIncrease() {
      if (this.isValidCompanion && this.companion.isActive && this.loyaltyCost <= this.countResource) {
        Haptics.impact({ style: ImpactStyle.Medium });
        const isPurchasing = await this.uiMsgBoxConfirm(
          `Are you sure you want to give ${this.loyaltyCost} ${this.resource.label.toLowerCase()} to ${this.companion.label}?`,
        );

        if (isPurchasing) {
          const result = await this.$store.dispatch('userSettings/setCompanion', {
            name: this.companion.name,
            expiresAt: this.$dayjs(this.companion.expiresAt).add(1, 'day').toDate(),
          });

          if (result) {
            await this.$store.dispatch('userSettings/editResources', [{
              count: this.countResource - this.loyaltyCost,
              name: this.resource.name,
            }]);

            Haptics.vibrate();
            this.uiNotify('Companion time extended!');
          }
        }
      }
    },
  },
};
