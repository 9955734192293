
import regl from 'regl';

import shaders from '../lib/shaders-companion';

const WIDTH = 315;
const HEIGHT = 315;
const SHADER_ACTIVE = 'scanlines3';
const SHADER_INACTIVE = 'posterize';

export default {
  name: 'CompanionCardBackground',
  props: {
    companionName: {
      type: String,
      default: undefined,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    isVisible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      width: WIDTH,
      height: HEIGHT,
    };
  },
  computed: {
    rand() {
      return this.$store.getters.getRandom(this.actionId);
    },
    companion() {
      return this.$store.getters['companions/getCompanionByName'](this.companionName);
    },
    imgPath() {
      return this.companion ? `/companions/${this.companion.imgPath}` : undefined;
    },
  },
  created() {
    this._reglInstance = undefined;
  },
  mounted() {
    this.$nextTick(() => {
      this.initShader();
    });
  },
  beforeDestroy() {
    this.removeShader();
  },
  methods: {
    initShader() {
      const glCanvas = this.$refs['gl-canvas'];

      if (this._reglInstance || !glCanvas) {
        return;
      }

      if (!this.imgPath) {
        return;
      }

      const fragmentShader = shaders[this.isActive ? SHADER_ACTIVE : SHADER_INACTIVE].frag;
      const start = `// #version 300 es
      precision mediump float;

      uniform sampler2D iChannel0;
      uniform vec2 iResolution;
      uniform vec4 iMouse;
      uniform float iTime;
      uniform float rand;
      uniform float importanceMultiplier;
      uniform float completeness;
      `;

      const end = `
      void main() {
        mainImage(gl_FragColor.rgba, gl_FragCoord.xy);
      }
      `;

      this._reglInstance = regl(glCanvas);

      const img = new Image();
      img.crossorigin = 'anonymous';
      img.src = this.imgPath;

      let imgTexture = this._reglInstance.texture(null);

      img.onload = () => {
        imgTexture = this._reglInstance.texture(img);
      };

      const draw = this._reglInstance({
        // Shaders in regl are just strings.  You can use glslify or whatever you want
        // to define them.  No need to manually create shader objects.
        frag: `${start}${fragmentShader}${end}`,

        vert: `
        precision mediump float;
        attribute vec2 position;
        varying vec2 uv;
        void main () {
          uv = position;
          gl_Position = vec4(2.0 * position - 1.0, 0, 1);
        }`,

        // This tells regl the number of vertices to draw in this command
        count: 3,

        attributes: {
          position: [
            -2, 0,
            0, -2,
            2, 2]
        },

        uniforms: {
          iResolution: [WIDTH, HEIGHT],
          iMouse: [0, 0, 0, 0],
          iTime: ({ tick }) => 0.005 * tick,
          iChannel0: () => imgTexture,
          rand: this._reglInstance.prop('rand'),
        },
      });

      this._reglInstance.frame(() => {
        if (!this.isVisible) {
          return;
        }

        // clear contents of the drawing buffer
        this._reglInstance.clear({
          color: [0, 0, 0, 0],
          depth: 1
        })

        draw({
          rand: this.rand,
        });
      });
    },
    removeShader() {
      if (this._reglInstance) {
        this._reglInstance.destroy();
      }
    },
  },
};
